<template>
    <div class="sidebar-wrapper">
        <router-link :to="getHomeUrl()" class="logo-route">
            <div class="logo-wrapper">
                <img src="../assets/images/logo.png" class="logo-img" alt="">
                <h2 class="title">IDSDP</h2>
            </div>
        </router-link>
        <div class="sidebar-navigation">
            <CollapseMenuMinistry :items="items" :open="true" :horizontal="horizontal" :sidebarGroupTitle="sidebarGroupTitle"/>
        </div>
    </div>
</template>
<script>
import RestApi, { commonServiceBaseUrl } from '@/config/api_config'
import CollapseMenuMinistry from './menus/CollapseMenuMinistry.vue'
import { mapGetters } from 'vuex'
import { EventBus } from '@/EventBusLayout'
export default {
    Name: 'Sidebar',
    props: ['name'],
    components: {
        CollapseMenuMinistry
    },
    data () {
        return {
            items: [],
            homeURL: { type: Object, default: () => ({ path: '/auth/dashboard' }) },
            logo: { type: String, default: require('../assets/images/logo.png') },
            horizontal: true,
            toggleButton: true,
            logoShow: true,
            onlyLogo: true,
            onlyLogoText: true,
            sidebarGroupTitle: true
        }
    },
    created () {
        this.getMinistryMenus()
    },
    computed: {
        lang: function () {
            return this.$i18n.locale
        },
        ...mapGetters({
            authUser: 'Auth/authUser'
        })
    },
    methods: {
        async getMinistryMenus () {
            const response = await RestApi.getData(commonServiceBaseUrl, '/ministry-dashboard-menu/sidebar-menus')
            this.loading = false
            if (response.success) {
                const menus = response.data
                const menuSort = []
                menus.forEach((item, key) => {
                    menuSort.push(item)
                })
                const panelMenus = menuSort.map(item => {
                    return Object.assign({}, this.getFormatedMenu(item))
                })
                this.items = panelMenus
                EventBus.$emit('MINISTRY_DASHBOARD_NAMES', menus)
            } else {
                this.items = []
            }
        },
        getFormatedMenu (item) {
            return {
                title: item.menu_name,
                title_bn: item.menu_name_bn,
                name: this.lang === 'en' ? item.menu_name : item.menu_name_bn,
                is_heading: false,
                is_active: false,
                link: item.url,
                class_name: 'iq-submenu-data',
                is_icon_class: true,
                icon: item.icon,
                panel_id: item.component_id
            }
        },
        getHomeUrl () {
            if (this.authUser && this.authUser.user_type === 1) {
                return '/auth/dashboard'
            } else {
                return '/external-user/panel'
            }
        }
    }
}
</script>
