import RestApi, { commonServiceBaseUrl } from '@/config/api_config'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      //
    }
  },
  computed: {
    hasDropdownLoadedCommonService () {
      return this.$store.state.CommonService.commonObj.hasDropdownLoaded
    },
    ...mapGetters({
      commonObj: 'CommonService/commonObj',
      authUser: 'Auth/authUser',
      activeRoleId: 'Auth/getActiveRole'
    }),
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList
    }
  },
  watch: {
    hasDropdownLoadedCommonService: function (newValue) {
      if (!newValue) {
        this.loadCommonServiceDropdown()
      }
    },
    fiscalYearList (newValue) {
      this.setCurrentFiscalYear(newValue)
    }
  },
  created () {
    const hasDropdownLoadedCommonService = this.$store.state.CommonService.commonObj.hasDropdownLoaded
    if (!hasDropdownLoadedCommonService || window.performance) {
      this.loadCommonServiceDropdown()
    }
  },
  methods: {
    loadCommonServiceDropdown () {
      RestApi.getData(commonServiceBaseUrl, 'ministry-common-dropdown', null).then(response => {
        if (response.success) {
          this.$store.dispatch('CommonService/mutateCommonObj', {
            hasDropdownLoaded: true,
            countryList: response.data.countryList,
            divisionList: response.data.divisionList,
            districtList: response.data.districtList,
            upazilaList: response.data.upazilaList,
            thanaList: response.data.thanaList,
            unionList: response.data.unionList,
            cityCorporationList: response.data.cityCorporationList,
            municipalityList: response.data.municipalityList,
            fiscalYearList: this.fiscalYearBnAdd(response.data.fiscalYearList),
            wardList: response.data.wardList
          })
          this.$store.dispatch('CommonService/localizeDropdown', { value: this.$i18n.locale })
        }
      })
    },
    fiscalYearBnAdd (data) {
      const bnList = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯']
      const bnAdded = data.map(item => {
        const fiscalYearData = {}
        const result = item.text_bn.split('').map(item => item === '-' ? '-' : bnList[item])
        fiscalYearData.text_bn = result.join('')
        return Object.assign({}, item, fiscalYearData)
      })
      return bnAdded
    },
    setCurrentFiscalYear (fiscalYearList) {
      if (fiscalYearList.length === 0) {
        return
      }
      const currentDate = new Date()
      const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
      /** Define the year position  */
      const yearPosition = month < 7 ? 5 : 0
      const yearStr = `${year}`
      let currentFiscalYearId = 0
      fiscalYearList.forEach(element => {
        if (element.text_en.indexOf(yearStr) === yearPosition) {
          currentFiscalYearId = element.value
        }
      })
      this.$store.dispatch('setCurrentFiscalYearId', currentFiscalYearId)
    }
  }
}
